const CLIENT_ID = "sosnbmcyn2kd2ns05wdzbf5a0ux2g5";
const makeRequest = async (url, options) => {
  options.headers = {
    ...options.headers,
    "Client-ID": CLIENT_ID
  };

  return (await fetch(url, options)).json();
};

const getUser = async access_token => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Client-ID": CLIENT_ID
    }
  };
  return (await makeRequest("https://api.twitch.tv/helix/users", options))
    .data[0];
};

const changeTwitchTitle = async (access_token, broadcast_id, title) => {
  const body = {
    title
  };
  const options = {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${access_token}`,
      "Client-Id": CLIENT_ID
    },
    body: JSON.stringify(body)
  };

  console.log("Set twitch title to", title);
  await fetch(
    `https://api.twitch.tv/helix/channels?broadcaster_id=${broadcast_id}`,
    options
  );
};

module.exports = { makeRequest, getUser, changeTwitchTitle };
