<template>
  <div class="twitch">
    <h1>Twitch Account</h1>
    <div v-if="access_token" class="twitch-data">
      <img :src="user.profile_image_url" alt="" class="pp" />
      <p>{{ user.display_name }}</p>
    </div>
    <a
      v-if="!access_token"
      href="https://id.twitch.tv/oauth2/authorize?client_id=sosnbmcyn2kd2ns05wdzbf5a0ux2g5&redirect_uri=https://trophy.lyliya.fr/login&response_type=token&scope=channel:manage:broadcast user:read:email"
      >Login with twitch</a
    >
    <button v-if="access_token" @click="logout">Logout</button>
  </div>

  <hr />

  <div>
    <h1>Playstation Account</h1>
    <div>
      <p>
        Enter your NPSSO (<a
          href="https://ca.account.sony.com/api/v1/ssocookie"
          target="_blank"
          >Get it here</a
        >)
      </p>
      <input
        type="password"
        v-model="npsso"
        placeholder="npsso"
        @keyup.enter="onEnter"
      />
      <button @click="onEnter">Get games</button>
    </div>

    <br />

    <select v-if="games.length > 0" v-model="selected" class="status-select">
      <option
        v-for="(game, idx) of games"
        :key="idx"
        :value="game.npCommunicationId"
      >
        {{ game.trophyTitleName }}
      </option>
    </select>
  </div>

  <hr />

  <h1>Data</h1>

  <p>Change your twitch title</p>
  <ul>
    <li>{{ "\{\{earned\}\}" }} is the earned amount of trophy</li>
    <li>{{ "\{\{total\}\}" }} is the total amount of trophy</li>
    <li>{{ "\{\{game\}\}" }} is the game name</li>
  </ul>
  <input type="text" name="title" placeholder="title" v-model="title" />

  <button v-if="!interval" @click="start">Start</button>
  <button v-if="interval" @click="stop">Stop</button>

  <p class="log">{{ log }}</p>

  <hr />
  <h1>Trophies</h1>

  <h2>Earned : {{ earned }} / Total : {{ total }}</h2>

  <div v-if="show_trophy">
    <button @click="toggleTrophy">Hide trophy</button>
    <div class="trophy-container">
      <div
        v-for="(trophy, idx) of trophies"
        :key="idx"
        class="trophy"
        :class="trophy.isEarned ? 'earned' : 'not-earned'"
      >
        <img :src="trophy.trophyIconUrl" />
        <div class="trophy-data">
          <h1>{{ trophy.trophyName }}</h1>
          <p>{{ trophy.trophyDetail }}</p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!show_trophy">
    <button @click="toggleTrophy">Show trophy</button>
  </div>
</template>

<script>
import { getUser, changeTwitchTitle } from "@/helpers/twitch.js";

const API_URL = "https://api.trophies.lyliya.fr";

export default {
  name: "App",
  components: {},
  data() {
    return {
      games: [],
      npsso: "",
      trophies: [],
      access_token: "",
      user: {},
      selected: "",
      log: "",
      interval: null,
      title: "{{game}} {{earned}}/{{total}}",
      show_trophy: false
    };
  },

  computed: {
    earned() {
      return this.trophies.filter(e => e.isEarned === true).length;
    },
    total() {
      return this.trophies.length;
    }
  },

  methods: {
    onEnter: function () {
      this.getUserGames();
    },

    getTwitchUser: async function () {
      if (!this.access_token) return;
      this.user = await getUser(this.access_token);
    },

    getUserGames: async function () {
      localStorage.setItem("npsso", this.npsso);

      const res = await fetch(`${API_URL}/game?npsso=${this.npsso}`);
      const json = await res.json();
      this.games = json;
    },

    getTrophies: async function (title) {
      const body = {
        title
      };

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(body)
      };

      const res = await fetch(
        `${API_URL}/trophies?npsso=${this.npsso}&gameId=${title.npCommunicationId}&platform=${title.trophyTitlePlatform}`,
        options
      );
      const json = await res.json();
      this.trophies = json;
    },

    setTitle: async function () {
      const selected = this.games.find(
        e => e.npCommunicationId == this.selected
      );
      await this.getTrophies(selected);
      let tmp = this.title;
      tmp = tmp.replaceAll("{{earned}}", this.earned);
      tmp = tmp.replaceAll("{{total}}", this.total);
      tmp = tmp.replaceAll("{{game}}", selected.trophyTitleName);

      changeTwitchTitle(this.access_token, this.user.id, tmp);
    },

    start: function () {
      if (!this.selected) {
        this.log = "Please select a game";
        return;
      }
      if (!this.access_token) {
        this.log = "Please connect your twitch account";
        return;
      }
      this.log = "";
      this.setTitle();
      this.interval = setInterval(this.setTitle, 1000 * 60 * 3);
    },

    stop: function () {
      clearInterval(this.interval);
      this.interval = null;
    },

    logout: function () {
      this.access_token = "";
      localStorage.removeItem("token");
    },

    toggleTrophy: function () {
      this.show_trophy = !this.show_trophy;
    }
  },

  mounted() {
    //this.getUserGames();
    this.access_token = localStorage.getItem("token");
    this.npsso = localStorage.getItem("npsso");
    this.title = localStorage.getItem("title");
    this.getTwitchUser();
  },

  watch: {
    selected: function () {
      console.log(this.selected);
      const selected = this.games.find(
        e => e.npCommunicationId == this.selected
      );
      this.getTrophies(selected);
    },
    title: function () {
      localStorage.setItem("title", this.title);
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

input {
  width: 500px;
  text-align: center;
}

.twitch-data {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 1.5em;
}

.pp {
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.log {
  color: red;
}

.trophy {
  text-align: start;
  display: flex;
  margin: 10px;
  width: 600px;
  height: 200px;
}

.trophy > img {
  margin: 10px;
  /* height: 150px; */
  margin-right: 20px;
}

.trophy-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.trophy-data > p {
  margin: 5px;
}

.trophy-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.earned {
  background-color: rgb(49, 107, 49) !important;
}

.not-earned {
  background-color: rgb(100, 100, 100);
}
</style>
