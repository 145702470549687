<template>
  <div class="login">
    <h1>{{ access_token }}</h1>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {
    // Login
  },
  data() {
    return {
      access_token: ""
    };
  },
  async mounted() {
    if (!this.$route.hash.includes("access_token"))
      return this.$router.push("/");
    localStorage.setItem("token", this.$route.hash.split("&")[0].split("=")[1]);
    return this.$router.push("/");
  }
};
</script>
